$(function() {

    const ship = [
        {point: 0, x: 0, y: 0},
        {point: 10, x: -80.8, y: -.95},
        {point: 20, x: -161.6, y: -3.56},
        {point: 30, x: -242.4, y: -6.47},
        {point: 40, x: -323.2, y: -9.07},
        {point: 50, x: -404, y: -10},
        {point: 60, x: -484.8, y: -9.07},
        {point: 70, x: -565.6, y: -6.47},
        {point: 80, x: -646.4, y: -3.56},
        {point: 90, x: -727.2, y: -.95},
        {point: 100, x: -808, y: 0}
    ];
    const backWaves = [
        {point: 0, x: 0, y: 0},
        {point: 100, x: -80, y: 0}
    ];
    const middleWaves = [
        {point: 0, x: 0, y: 0},
        {point: 10, x: -16.2, y: 1.9},
        {point: 20, x: -32.4, y: 7.12},
        {point: 30, x: -48.6, y: 12.94},
        {point: 40, x: -64.8, y: 18.14},
        {point: 50, x: -81, y: 20},
        {point: 60, x: -97.2, y: 18.14},
        {point: 70, x: -113.4, y: 12.94},
        {point: 80, x: -129.6, y: 7.12},
        {point: 90, x: -145.8, y: 1.9},
        {point: 100, x: -162, y: 0}
    ];
    const frontWaves = [
        {point: 0, x: 0, y: 0},
        {point: 15, x: -38.4, y: -3.15},
        {point: 30, x: -76.8, y: -9.19},
        {point: 45, x: -115.2, y: -15.36},
        {point: 60, x: -153.6, y: -20.13},
        {point: 65, x: -166.4, y: -20.5},
        {point: 70, x: -179.2, y: -20.02},
        {point: 75, x: -192, y: -17.72},
        {point: 80, x: -204.8, y: -13.05},
        {point: 85, x: -217.6, y: -7.64},
        {point: 90, x: -230.4, y: -2.89},
        {point: 95, x: -243.2, y: -.52},
        {point: 100, x: -256, y: 0}
    ];

    const $testingSlider = $('#testing-slider'),
        $testingHeader = $('#testing-header'),
        $frontWaves = $('#front_waves'),
        $middleWaves = $('#middle_waves'),
        $backWaves = $('#back_waves'),
        $ship = $('#ship'),
        $shipSmall = $('#ship_small'),
        $duckSmall = $('#duck_small'),
        limit = $('[id*="testing-level"]', $testingSlider).length - 1,
        $sandwich = $('#menu-sandwich'),
        $menuMain = $('#menu-main'),
        $modal = $('#modal'),
        $popup = $('#popup');
    let step = 0,
        score = 0,
        $testBtn;

    function animationStart() {
        $testingSlider.css({
            opacity: 0
        });
    }

    function animationComplete(n) {
        $testingSlider.css({
            opacity: 1
        });
        step = n;
    }

    function table_animation($obj, table, ns, nf, callback) {
        ns = ns || 0;
        nf = nf || 0;
        const ln = table.length - 1;
        if (ln > 0) {
            let n = Math.abs(ns - nf),
                f = nf < ns ? -1 : 1,
                f0 = nf < ns ? ln : 0,
                fl = nf < ns ? 0 : ln,
                as = nf < ns ? 100 * n : 0,
                af = nf < ns ? 0 : 100 * n,
                sPoint = f0,
                fPoint = nf < ns ? ln - 1 : 1,
                i = nf < ns ? n - 1 : 0,
                add = nf < ns ? nf : ns;
            $({'t': as}).stop().animate({'t': af}, {
                step:     function(now) {
                    let s = now - table[ln].point * i;
                    const k = (s - table[sPoint].point) / (table[fPoint].point - table[sPoint].point),
                        xOffset = (table[fPoint].x - table[sPoint].x) * k + table[ln].x * (i + add),
                        yOffset = (table[fPoint].y - table[sPoint].y) * k + table[ln].y * (i + add),
                        x = Math.round((table[sPoint].x + xOffset) * 10) / 10,
                        y = Math.round((table[sPoint].y + yOffset) * 10) / 10;
                    $obj.css({
                        transform: `translate(${x}px, ${y}px)`
                    });
                    if (f * s > f * table[fPoint].point) {
                        sPoint += f;
                        fPoint += f;
                    }
                    if (f * sPoint >= fl) {
                        sPoint = f0;
                        fPoint = f0 + f;
                        i += f;
                    }
                    if (f * fPoint > fl) {
                        fPoint = f0;
                    }
                },
                complete: function() {
                    if (callback) callback(nf);
                },
                duration: 2000 * n,
                easing:   'easeInOutQuad'
            });
        }
    }

    if ($testingSlider.length) {
        $testingSlider.slick({
            initialSlide: limit,
            speed:        2000,
            infinite:     false,
            draggable:    false,
            swipe:        false,
            fade:         true,
            prevArrow:    '<div class="slider__prev">⟵ Уровень</div>',
            nextArrow:    '<div class="slider__next">Уровень ⟶</div>'
        });
        $testingSlider.on('beforeChange', function(s, e, c, n) {
            animationStart();
            table_animation($frontWaves, frontWaves, c, n);
            table_animation($middleWaves, middleWaves, c, n);
            table_animation($duckSmall, middleWaves, c, n);
            table_animation($backWaves, backWaves, c, n);
            table_animation($ship, ship, c, n, animationComplete);
        });
    }
    $('#game-start')
        .on('click', function() {
            animationStart();
            table_animation($frontWaves, frontWaves, -2, limit);
            table_animation($middleWaves, middleWaves, -2, limit);
            table_animation($duckSmall, middleWaves, -2, limit);
            table_animation($backWaves, backWaves, -2, limit);
            table_animation($ship, ship, -2, limit, animationComplete);
            $({t: 0}).stop().animate({t: 100}, {
                step:     function(now) {
                    $testingHeader.css({
                        opacity: 1 - now / 100
                    });
                    $shipSmall.css({
                        transform: `translateX(${-16.16 * now}px)`
                    })
                },
                complete: function() {
                    $testingHeader.hide();
                    $shipSmall.hide();
                },
                duration: 2000,
                easing:   'easeInOutQuad'
            });
        });

    document.addEventListener('test.passed', function() {
        $.get(Routing.generate('my_rating_by_event', {type: 1, id: $testingSlider.data('workshop')}))
            .done(function(data) {
                score = parseInt(data);

                function show_level_popup($target) {
                    $popup.fadeOut(300, function() {
                        $('.popup', $modal).hide();
                        $target.show();
                        $popup.fadeIn(300);
                    });
                }

                const i = step + 1,
                    level = document.querySelectorAll('[data-level="' + i + '"] form'),
                    score_limit = $('#testing-level-' + i).data('score-limit');

                $testBtn.addClass('disabled');
                let timer = setInterval(function() {
                    const t = document.getElementsByClassName('testContent').length;
                    if (t >= (limit + 1) * 3) {
                        clearInterval(timer);
                        let hint = 0;
                        for (let j = 0; j < limit + 1; j++) {
                            let testLevel = document.querySelectorAll('[data-level="' + (j + 1) + '"] form');
                            if (testLevel.length <= 0) hint++;
                        }
                        console.log(hint);
                        if (level.length <= 0) {
                            if (score >= score_limit) {
                                show_level_popup($('#result-' + hint));
                            } else {
                                show_level_popup($('#game-over'));
                            }
                            animationStart();
                            table_animation($frontWaves, frontWaves, step, 4);
                            table_animation($middleWaves, middleWaves, step, 4);
                            table_animation($duckSmall, middleWaves, step, 4);
                            table_animation($backWaves, backWaves, step, 4);
                            table_animation($ship, ship, step, 4);
                            $modal.on('click', function() {
                                setTimeout(function() {
                                    location.hash = '#testing';
                                    location.reload();
                                }, 500);
                            });
                        } else {
                            $modal.trigger('click');
                        }
                    }
                }, 100);
            });
    });

    $sandwich
        .on('click', function() {
            const $t = $(this);
            $t.toggleClass('active');
            if ($t.hasClass('active')) {
                $menuMain.addClass('active');
            } else {
                $menuMain.removeClass('active');
            }
        });

    $('[data-role="popup-show"]')
        .on('click', function() {
            const $t = $(this),
                target = $t.data('target'),
                $target = $('#' + target),
                $scroll = $('[data-role="scroll"]', $target);
            if ($t.hasClass('test__level-btn')) {
                $testBtn = $t;
            }
            if ($target.length) {
                if (target === 'video') {
                    const vid = $t.data('vid');
                    $target.append(
                        $('<iframe/>').attr({
                            width:           '100%',
                            height:          '100%',
                            src:             'https://www.youtube.com/embed/' + vid,
                            frameborder:     0,
                            allow:           'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
                            allowfullscreen: ''
                        })
                    );
                }
                $target.show();
                $('html,body').css('overflow', 'hidden');
                $('#modal').fadeIn(300, function() {
                    if ($scroll.length) {
                        $scroll.perfectScrollbar({
                            wheelPropagation:   true,
                            minScrollbarLength: 30
                        });
                    }
                });
            }
            return false;
        });

    $modal
        .on('click', function(e) {
            if (e.target === $modal.get(0)) {
                $modal.fadeOut(300, function() {
                    $('iframe', $modal).remove();
                    $('.popup, .popup_level, .popup_total, .popup_game-over, .popup_video, .popup_rules', $modal).hide();
                    $('html,body').css('overflow', 'auto');
                });
            }
        });

    if ($(window).scrollTop() <= 500) {
        $sandwich.addClass('active');
        $menuMain.addClass('active');
    }

    $(window)
        .scroll(function() {
            const st = $(this).scrollTop();
            if (st > 580) {
                $sandwich.removeClass('active');
                $menuMain.removeClass('active');
            } else {
                $sandwich.addClass('active');
                $menuMain.addClass('active');
            }
        });

    console.log('Document ready');
});